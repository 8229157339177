$black: #2E3A47;
$green100: #1B533F;
$green90: #15694C;
$green80: #1D7656;
$green70: #26805F;
$green30: #46977C;
$green20: #69BFA3;
$green10: #A3DCC9;
$green05: #ECFCF6;
$gray100: #2E3A47;
$gray90: #36485C;
$gray80: #415B73;
$gray70: #58728C;
$gray30: #9BB1C7;
$gray20: #C5D7E5;
$gray10: #F0F5FC;
$gray05: #F5F8FC;
$blue100: #183C5E;
$blue90: #1E4D78;
$blue80: #215F8F;
$blue70: #3175A8;
$blue30: #BED4E8;
$blue20: #CEE1F2;
$blue10: #E1EEFA;
$blue05: #F8FCFF;
$red100: #640C29;
$red90: #971038;
$red80: #B91C3F;
$red70: #DA1B46;
$red30: #E1496B;
$red20: #F98FA6;
$red10: #FFDCE3;
$red05: #FFF9FA;
$orange100: #B4561D;
$orange90: #D96824;
$orange80: #E57812;
$orange70: #F89C1C;
$orange30: #FCCB88;
$orange20: #FFE6C4;
$orange10: #FFF5E8;
$orange05: #FFFBF6;
$purple100: #2E0141;
$purple90: #401256;
$purple80: #58316B;
$purple70: #816290;
$purple30: #B69AC4;
$purple20: #CEBAD9;
$purple10: #EEE4F2;
$purple05: #FBF9FD;
$greenery: #88b04b;
$greeneryLight: #9ABC66;
$greeneryActive: #7A9E43;
$greeneryExtraLight: #F8FAF5;
$green25opacity: #E1EBD2;
$green5opacity: #F9FBF6;
$silt: #ABBDB1;
$highlighter: #FBF8E5;
$white: #ffffff;
$facebookBlue: #3C5A99;
$twitterBlue: #1DA1F2;
$linkedInBlue: #2867B2;
$legacyBlueGreyLight: #f8fafb;
$legacyBlueGrey: #7793a9;
$legacyBorder: #d6ddd8;
$legacyBabyBlue: #d1e2f3;
