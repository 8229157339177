@import "box/box-overrides";
@import "button/button-overrides";
@import "input/input-overrides";
@import "navbar/navbar-overrides";
@import "radio/radio-overrides";
@import "tag/tag-overrides";
@import "title/title-overrides";

a {
  @include transition(0.2s);
}

@media only screen and (max-width: $tablet) {
  .field.is-grouped {
    flex-wrap: wrap;
  }

  .field.is-grouped > .control:not(:last-child) {
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
  }
}
