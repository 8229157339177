@charset "utf-8";

// Global variables
@import "./variables";

// Mixins
@import "./mixins";

// Override default Bulma settings
@import "./bulma_variable_overrides";

@import "./fullscript-bulma-theme/variables";

// Bulma
@import "../../node_modules/bulma/bulma.sass";

// Bulma Theme Style Overrides
@import "./fullscript-bulma-theme/overrides";

// Padding Helpers
@import "./padding_helpers";

@import "~bulma-switch";
@import "~bulma-tooltip";

html {
  background-color: $background-blue;
}

body {
  background-color: $background-blue;
}

.columns.is-gapless:last-child {
  margin-bottom: 40px;
}

code {
  color: $grape-kiss;
}

.input {
  display: block;
  width: 100%;
  height: 44px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.column.is-vcentered {
  margin-top: auto;
  margin-bottom: auto;
}

.main {
  @extend .is-9;
}

.message-body div.notification {
  margin-bottom: 30px;
}

.tabs li a {
  background-color: $white;
}
.tabs:not(:last-child) {
  margin-bottom: 0px;
}
p button {
  margin-left: 30px;
}
.message {
  box-shadow: 0 2px 4px 0 rgba(64, 79, 94, 0.07);
}
#login-form.message {
  @media only screen and (max-width: 768px) {
    margin-top: 2%;
  }

  @media only screen and (min-width: 769px) {
    margin-top: 15%;
  }
}
.message.is-warning .message-header {
  background-color: $pink;
  color: #ffffff;
}
.message.is-warning {
  background-color: $white;
}

.modal-card-foot {
  background-color: $white;
}

.switch[type="checkbox"].is-warning:checked + label:before {
  background: $orange;
}

div.message-header.sandbox {
  border-bottom: 3px solid $orange;
  padding-bottom: 1.1em;
}
button.button.sandbox {
  background: $orange;
  color: $white;
}

.btn-md {
  width: 200px;
  height: 45px;
}

.centre {
  text-align: center;
}
