// Set brand colors
$green: $greenery;
$purple: $grape-kiss;
$pink: $calypso-coral;
$red: $calypso-coral;
$blue: $green;
$orange: $np-orange;

// Update Bulma's global variables
$family-sans-serif: $body-font;
$primary: $green;
$warning: $orange;
$danger: $red;
$purple-invert: $white;
$warning-invert: $purple-invert;
$dark: $orion-blue;
$light: $light-blue;

$family-sans-serif:  "Mulish", sofia-pro, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

$box-padding: 80px;

$message-header-background-color: $slate;
$message-background-color: $white;
$message-radius: 10px;
$message-body-padding: 1.75em 2.25em;
$message-header-padding: 1.25em 1.2em;

$modal-card-head-background-color: $fs-deep-light-grey;
$modal-card-head-border-bottom: none;
$modal-card-foot-border-top: none;
$modal-card-body-padding: 30px;
