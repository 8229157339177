.button {
  @include transition(0.2s);
  line-height: 1;
  padding-bottom: calc(#{$button-padding-vertical} + 0.05em);
  font-weight: 600;
  letter-spacing: $letter-spacing;
  background-color: transparent;

  &:hover,
  &.is-hovered {
    background-color: $dark;
  }

  &:focus,
  &.is-focused {
    background-color: $dark;
  }

  &:active,
  &.is-active {
    background-color: darken($dark, 5%);
    box-shadow: $button-active-box-shadow-size rgba($dark, 0.3);
  }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} &:focus,
    &.is-focused {
      background-color: darken($color, 5%);
      border-color: transparent;
      color: $color-invert;
      &:not(:active) {
        box-shadow: $button-focus-box-shadow-size rgba($color, 0.25);
      }
    }
    &:active,
    &.is-active {
      background-color: darken($color, 5%);
      border-color: transparent;
      color: $color-invert;
      box-shadow: $button-active-box-shadow-size rgba($color, 0.3);
    }
  }
}

.button.button-cart {
  .svg-inline--fa {
    margin-right: 5px;
    color: darken($greenery-hover, 10%);
  }
}
