/* stylelint-disable */
// *
// * Palette Colors
// *
// * @usage: defines colors from Warren Dunlop's Fullscript Brand Guide
// *         found at https://brand.fullscript.com/internal

$header-font: "Montserrat", sans-serif;
$body-font: "Karla", sans-serif;

// Primary Colors
$greenery: #1d7656;

$orion-blue: #404f5e;

// Secondary Colors
$hydro: #486971;
$slate: #36485c;
$silt-green: #abbdb1;
$fullscript-blue: #3498db;
$calypso-coral: #ef5c6e;
$grape-kiss: #784067;
$np-orange: #b4561d;

$light-blue: #ebf0f5;
$background-blue: #ebf0f5;
$blue-grey: #7793a9;
$gold: #ffd700;
$price-grey: #aabcc4;
$border-green: lighten($silt-green, 14%);

// Off-Colors
$off-grey: #9aacbd;

// Hover States
$greenery-hover: #69922b;

// Shades
$white: #ffffff;
$black: #000000;

// *
// * Color Definitions
// *
// * @usage: Associates Palette colours with site variables already existing
// *         within the Fullscript/HealthWave Sass definitions

$fs-light-grey: #dbdbdb;
$fs-blue-grey: #f8fafb;
$fs-deep-light-grey: #f3f3f3;
$fs-grey: $slate;
$fs-dark-grey: $hydro;

// Patient side greys
$fs-patient-productcard-border: darken($white, 6.67);
$fs-patient-border: darken($fs-deep-light-grey, 15.29);
$fs-patient-lightest-gray: #fafafa;
$fs-sidebar-link-blue: #d1e2f3;
$fs-sidebar-icon-blue: #7793a9;

//borders
$btn-border-rad: 2px;

$hover: "&:hover, &:focus";

$letter-spacing: 0.01em;

$header-font: "Montserrat", sans-serif;
$body-font: "Karla", sans-serif;

// Primary Colors
$greenery: #1d7656;
$orion-blue: #404f5e;

// Secondary Colors
$hydro: #486971;
$slate: #36485c;
$silt-green: #abbdb1;
$fullscript-blue: #3498db;
$calypso-coral: #ef5c6e;
$grape-kiss: #784067;

$light-blue: #ebf0f5;
$background-blue: #ebf0f5;
$gold: #ffb820;
$price-grey: #aabcc4;
$border-green: lighten($silt-green, 14%);

// Off-Colors
$off-grey: #9aacbd;

// Hover States
$greenery-hover: #69922b;

// Shades
$white: #ffffff;
$black: #000000;

// *
// * Color Definitions
// *
// * @usage: Associates Palette colours with site variables already existing
// *         within the Fullscript/HealthWave Sass definitions

$fs-primary: $greenery;
$fs-secondary: $orion-blue;
$fs-warning: $grape-kiss;
$fs-danger: $calypso-coral;
$fs-info: $fullscript-blue;
$fs-mute: $slate;
$fs-more-muted: $silt-green;
$fs-light-grey: #dbdbdb;
$fs-deep-light-grey: #f3f3f3;
$fs-grey: $slate;
$fs-dark-grey: $hydro;

// Patient side greys

$fs-patient-productcard-border: darken($white, 6.67);
$fs-patient-border: darken($fs-deep-light-grey, 15.29);
$fs-patient-lightest-gray: #fafafa;
