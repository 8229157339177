.radio {
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + span,
  [type="radio"]:not(:checked) + span {
    @include transition(0.2);
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 17px;
    display: inline-block;
  }
  [type="radio"]:checked + span:before,
  [type="radio"]:not(:checked) + span:before {
    @include transition(0.2);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid $silt-green;
    border-radius: 100%;
    background: $white;
  }
  [type="radio"]:checked + span:after,
  [type="radio"]:not(:checked) + span:after {
    @include transition(0.2);
    content: "";
    width: 10px;
    height: 10px;
    background: $primary;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
  }
  [type="radio"]:not(:checked) + span:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:focus + span:before,
  [type="radio"]:active + span:before {
    box-shadow: $button-focus-box-shadow-size rgba($primary, 0.25);
  }
}
