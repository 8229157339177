@import "../aviary/styles/colors.scss";

.admonition {
  padding: 1rem;
  border-radius: 0.25rem;
  margin-block: 1rem;
}

.admonition-heading h5 {
  display: flex;
  font-weight: bold;
  text-transform: capitalize;
}

.admonition-icon {
  margin-right: 0.5rem;
  display: inline-flex;
  align-self: center;
}

.admonition-fyi {
  color: $blueDarkFamilyDark;
  background-color: $blueLightFamilyLight;
  border: 1px solid $blueDarkFamilyDark;

  .admonition-heading h5 {
    text-transform: uppercase;
  }
}

.admonition-important {
  color: $red90;
  background-color: $redLightFamilyLight;
  border: 1px solid $redDarkFamilyDark;
}

.admonition-tip {
  color: $greenDarkFamilyDark;
  background-color: $greenLightFamilyLight;
  border: 1px solid $greenDarkFamilyDark;
}
