// classic clearfix
@mixin clearfix {
  &:before,
  &:after {
    display: table;
    content: " ";
  }
  &:after {
    clear: both;
  }
}

// Contains an absolutely positioned, scrollable content area - likely using the height-fill mixin
@mixin relfix($height: 100%) {
  position: relative;
  height: $height;
}

// Output rem with a px fallback
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}
@mixin font-size($size) {
  font-size: $size;
  //font-size: calculateRem($size);
}

//
// Sets Styling for placeholders (font size, font weight, and color)
@mixin style-placeholder($font-size, $font-weight, $font-color) {
  ::-webkit-input-placeholder {
    @include font-size($font-size);
    color: $font-color;
    font-weight: $font-weight;
  }
  ::-moz-placeholder {
    @include font-size($font-size);
    color: $font-color;
    font-weight: $font-weight;
  }
  ::placeholder {
    @include font-size($font-size);
    color: $font-color;
    font-weight: $font-weight;
  }
}

//  Flexible mixin to fill remaining container height when it's not ideal to use percentage or pixel value
//  Overflow defaults to true, setting an overflow-y:auto; which can be overridden by supplying false
// THIS IS BAD Mixin, Please no use

@mixin height-fill($top, $bottom, $width: 100%, $overflow: true) {
  position: absolute;
  top: $top;
  bottom: $bottom;
  width: $width;
  @if ($overflow == true) {
    overflow-y: auto;
    overflow-x: hidden;
  } @else {
    overflow: hidden;
  }
}

//
//  Mixin for including transitions
//
@mixin transition($duration: 0.2s) {
  transition: all $duration ease-out;
  transform-style: preserve-3d;
}

// Common styles for brands cards
// $width: How much space in the container it takes up (20% would be 5 in a row)
// $margin: space between each item in the container

@mixin top-brand-card($width, $margin) {
  @include transition(0.2s);
  width: calc(#{$width} - #{$margin});
  margin-right: $margin;
  min-height: 90px;
  height: inherit;
  margin-bottom: $margin;
  max-height: 105px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid lighten($silt-green, 20%);
  position: relative;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 1px solid lighten($silt-green, 5%);
    box-shadow: 0 3px 6px rgba($orion-blue, 0.1);
    top: -2px;
  }
}

//
//  Flexible mixin for box-shadow
//
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}
//
// Flexible mixin for border-radius
//
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// Responsive Breakpoint Mixin
@mixin breakpoint($media) {
  @if $media == small {
    @media only screen and (min-width: $break-small) {
      @content;
    }
  } @else if $media == medium {
    @media only screen and (min-width: $break-medium) {
      @content;
    }
  } @else if $media == large {
    @media only screen and (min-width: $break-large) {
      @content;
    }
  } @else {
    // allows custom breakpoints
    @media only screen and (min-width: $media) {
      @content;
    }
  }
}

@mixin bs-breakpoint($media) {
  // Large desktop
  @if $media == lg {
    @media only screen and (min-width: 980px) and (max-width: 1199px) {
      @content;
    }
  }
  // Portrait tablet to landscape and desktop
  @else if $media == md {
    @media only screen and (min-width: 768px) and (max-width: 979px) {
      @content;
    }
  }
  // Landscape phone to portrait tablet
  @else if $media == sm {
    @media only screen and (max-width: 767px) {
      @content;
    }
  }
  // Landscape phones and down
  @else if $media == xs {
    @media only screen and (max-width: 480px) {
      @content;
    }
  } @else {
    // allows custom breakpoints
    @media only screen and (max-width: $media) {
      @content;
    }
  }
}
