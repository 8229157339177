/*---------- PADDING HELPERS ----------*/
/*Flexible mixin for margin and padding*/
@use "sass:math";

@for $value from -100 through 100 {
  $result: 0;
  $output: true;

  @if $value < -20 or $value > 20 {
    $output: (($value % 5) == 0);
    $result: floor(math.div($value, 5)) * 5;
  } @else {
    $result: $value;
    $output: true;
  }

  /* Examples of usage: m10 mt10 mb10 mr10 ml10 */
  @if $output {
    .m#{$result} {
      margin: 1px * $result;
    }
    .mt#{$result} {
      margin-top: 1px * $result;
    }
    .mb#{$result} {
      margin-bottom: 1px * $result;
    }
    .mr#{$result} {
      margin-right: 1px * $result;
    }
    .ml#{$result} {
      margin-left: 1px * $result !important;
    }
    .p#{$result} {
      padding: 1px * $result;
    }
    .pt#{$result} {
      padding-top: 1px * $result;
    }
    .pb#{$result} {
      padding-bottom: 1px * $result;
    }
    .pr#{$result} {
      padding-right: 1px * $result;
    }
    .pl#{$result} {
      padding-left: 1px * $result;
    }
    .rt#{$result} {
      position: relative;
      top: 1px * $result;
    }
    .rb#{$result} {
      position: relative;
      bottom: 1px * $result;
    }
    .rr#{$result} {
      position: relative;
      right: 1px * $result;
    }
    .rl#{$result} {
      position: relative;
      left: 1px * $result;
    }
    .xs-m#{$result} {
      @media (max-width: 768px) {
        margin: 1px * $result;
      }
    }
    .xs-mt#{$result} {
      @media (max-width: 768px) {
        margin-top: 1px * $result;
      }
    }
    .xs-mb#{$result} {
      @media (max-width: 768px) {
        margin-bottom: 1px * $result;
      }
    }
    .xs-mr#{$result} {
      @media (max-width: 768px) {
        margin-right: 1px * $result;
      }
    }
    .xs-ml#{$result} {
      @media (max-width: 768px) {
        margin-left: 1px * $result;
      }
    }
    .sm-m#{$result} {
      @media (min-width: 768px) {
        margin: 1px * $result;
      }
    }
    .sm-mt#{$result} {
      @media (min-width: 768px) {
        margin-top: 1px * $result;
      }
    }
    .sm-mb#{$result} {
      @media (min-width: 768px) {
        margin-bottom: 1px * $result;
      }
    }
    .sm-mr#{$result} {
      @media (min-width: 768px) {
        margin-right: 1px * $result;
      }
    }
    .sm-ml#{$result} {
      @media (min-width: 768px) {
        margin-left: 1px * $result;
      }
    }
  }
}
